@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/base/spacing';

.InlineAlert {
  display: grid;
  display: flex;
  flex-direction: row;
  grid-template-columns: auto 1fr;
  margin: $spacing-small 0;
  padding: $spacing-small;

  &--success {
    border: 1px solid $green;
  }

  &--error {
    border: 1px solid $red;
    svg {
      fill: $red;
    }
  }

  &--info {
    border: 1px solid $light-blue;
  }

  &--breach {
    border: 1px solid $purple;
  }

  p {
    color: $dark-grey;
    flex: 1 1 auto;
    font-size: $caption;
    font-weight: normal;
    padding-left: $spacing-standard;
    margin-bottom: 0;
  }
}
