$sidebar-width: 60px;

$widget-height: 30vh;
$widget-width: 100%;

$context-bar-height: 54px;
$context-bar-min-height: 54px;
$context-bar-width: 100%;

$ecosystem-header-height: 72px;
$ecosystem-footer-height: 76px;

$desktop-width: 1366px;


$media-desktop: 'only screen and (min-width: #{$desktop-width})';

$checkbox-width: 24px;
