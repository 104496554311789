.search {
  width: 33%;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 10px;
}

.sectorFilter {
  width: 33%;
  display: inline-block;
}


.topright {
  position: absolute;
  top: 8px;
  right: 16px;
}

.title {
  margin-top: 10px;
}

.newsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  position: relative; 
  margin-bottom: 5px;
}

/* For viewports smaller than 1250px wide, show 3 columns */
@media screen and (max-width: 1250px) {
  .newsGrid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px ;
    position: relative; 
    overflow-x: none;
  }
}

/* media query for tablets */
@media screen and (max-width: 834px) {
  .newsGrid {
    grid-template-columns: 1fr;
    grid-column-gap: 10px ;
    position: relative; 
    overflow-x: none;
  }

  .fillGrid {
    height: 100px;
    max-width: 88% !important;
  }
}

@media screen and (max-width: 820px) {
  .newsGrid {
    grid-template-columns: 1fr;
    grid-column-gap: 10px ;
    position: relative; 
    overflow-x: none;
  }

  .fillGrid {
    height: 100px;
    max-width: 87% !important;
  }
} 

@media screen and (max-width: 800px) {
  .newsGrid {
    grid-template-columns: 1fr;
    grid-column-gap: 10px ;
    position: relative; 
    overflow-x: none;
  }

  .fillGrid {
    height: 100px;
    max-width: 84% !important;
  }
}

.fillGrid {
  height: 260px;
}

.pill {
  position: absolute; 
  bottom: 0;
  padding-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  
  a {
    margin-left: 5px !important;
  }
}

.badge {
  margin-right: 5px;
  cursor: pointer;
  height: fit-content;
}

.scrollToTopButton {
  position: fixed;
  bottom: 12%;
  right: 4%;
  color: #e55a00;
  border: none;
  font-size: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;

  &:hover, &:focus {
    outline: none;
  }
}

.scrollToTopButton.isvisible {
  opacity: 0.4;
  pointer-events: auto;
  &:hover {
    opacity: 1;
  }

}