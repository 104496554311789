@import '../../../stylesheets/themes/ecosystem-default/shadows';
@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';

.Select {
  background: none;

  &__indicator-separator {
    display: none;
  }

  &__control {
    border: 1px solid $grey;
    border-radius: 4px;
    box-shadow: $shadow-sm;
    padding: $spacing-nano 0;

    svg {
      fill: $bright-orange;
    }

    &:hover {
      border: 1px solid $grey !important;

      svg {
        fill: $dark-orange;
      }
    }

    &--is-focused {
      border: 1px solid $grey !important;
      box-shadow: $shadow-focus !important;
    }
  }

  &__menu {
    border: 0;
    box-shadow: $shadow-lg !important;
  }

  &__option {
    background-color: $light-grey;
    color: $mid-grey !important;
    letter-spacing: .25px;

    $selected-color: rgba($light-blue, .5) !important;

    &--is-focused,
    &:hover:not(&--is-selected) {
      background-color: $bg-grey !important;
    }

    &--is-selected {
      background-color: $selected-color;
      color: $dark-grey !important;
    }

    &--is-disabled {
      color: $light-grey !important;
    }
  }


  &__multi-value {
    background-color: $bg-grey !important;
    border-radius: 0 !important;

    &__label {
      color: $dark-grey !important;
      font-size: $body-baseline !important;
    }

    &__remove svg {
      height: 22px;
      width: 22px;
    }
  }
}
