@import '../../../stylesheets/base/sizes';
@import '../../../stylesheets/base/spacing';

.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: $spacing-tiny;
  width: 100%;

  &__Item {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    margin-bottom: $spacing-tiny;
  }

  &__justifyItems-end {
    justify-items: end;
  }

  &__justifyItems-center {
    justify-items: center;
  }

  &__justifyItems-start {
    justify-items: start;
  }

  &__alignItems-end {
    align-items: end;
  }

  &__alignItems-center {
    align-items: center;
  }

  &__alignItems-start {
    align-items: start;
  }

  &__justifyContent-end {
    justify-content: end;
  }

  &__justifyContent-start {
    justify-content: start;
  }

  &__justifyContent-center {
    justify-content: center;
  }

  &__justifyContent-space-around {
    justify-content: space-around;
  }

  &__justifyContent-space-between {
    justify-content: space-between;
  }

  &__justifyContent-space-evenly {
    justify-content: space-evenly;
  }

  &__alignContent-end {
    justify-content: end;
  }

  &__alignContent-start {
    align-content: start;
  }

  &__alignContent-center {
    align-content: center;
  }

  &__alignContent-space-around {
    align-content: space-around;
  }

  &__alignContent-space-between {
    align-content: space-between;
  }

  &__alignContent-space-evenly {
    align-content: space-evenly;
  }

  &__gap-micro {
    grid-column-gap: $spacing-micro;
    grid-row-gap: $spacing-micro;
  }

  &__gap-small {
    grid-column-gap: $spacing-small;
    grid-row-gap: $spacing-small;
  }

  &__gap-semi {
    grid-column-gap: $spacing-semi;
    grid-row-gap: $spacing-semi;
  }

  &__gap-smallest {
    grid-column-gap: $spacing-smallest;
    grid-row-gap: $spacing-smallest;
  }

  &__gap-large {
    grid-column-gap: $spacing-large;
    grid-row-gap: $spacing-large;
  }

  &__gap-standard {
    grid-column-gap: $spacing-standard;
    grid-row-gap: $spacing-standard;
  }

  &__desktop-col1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &__desktop-col2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &__desktop-col3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &__desktop-col4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &__desktop-col5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &__desktop-col6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &__desktop-col7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &__desktop-col8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &__desktop-col9 {
    grid-template-columns: repeat(9, 1fr);
  }

  &__desktop-col10 {
    grid-template-columns: repeat(10, 1fr);
  }

  &__desktop-col11 {
    grid-template-columns: repeat(11, 1fr);
  }

  &__desktop-col12 {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .Container {
    &__gap-micro {
      grid-column-gap: $spacing-micro / 2;
      grid-row-gap: $spacing-micro / 2;
    }

    &__gap-small {
      grid-column-gap: $spacing-small / 2;
      grid-row-gap: $spacing-small / 2;
    }

    &__gap-semi {
      grid-column-gap: $spacing-semi / 2;
      grid-row-gap: $spacing-semi / 2;
    }

    &__gap-smallest {
      grid-column-gap: $spacing-smallest / 2;
      grid-row-gap: $spacing-smallest / 2;
    }

    &__gap-large {
      grid-column-gap: $spacing-large / 2;
      grid-row-gap: $spacing-large / 2;
    }

    &__gap-standard {
      grid-column-gap: $spacing-standard / 2;
      grid-row-gap: $spacing-standard / 2;
    }

    &__tablet-col1 {
      grid-template-columns: repeat(1, 1fr);
    }

    &__tablet-col2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &__tablet-col3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &__tablet-col4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &__tablet-col5 {
      grid-template-columns: repeat(5, 1fr);
    }

    &__tablet-col6 {
      grid-template-columns: repeat(6, 1fr);
    }

    &__tablet-col7 {
      grid-template-columns: repeat(7, 1fr);
    }

    &__tablet-col8 {
      grid-template-columns: repeat(8, 1fr);
    }

    &__tablet-col9 {
      grid-template-columns: repeat(9, 1fr);
    }

    &__tablet-col10 {
      grid-template-columns: repeat(10, 1fr);
    }

    &__tablet-col11 {
      grid-template-columns: repeat(11, 1fr);
    }

    &__tablet-col12 {
      grid-template-columns: repeat(12, 1fr);
    }
  }
}

@media screen and (min-width: 1500px) {
  .Wrapper {
    margin: 0 auto;
    width: 1496px;
  }
}

@media screen and (max-width: 1500px) {
  .Wrapper {
    margin: 0 $spacing-semi;
  }
}

@media screen and (max-width: 1024px) {
  .Wrapper {
    margin: 0 $spacing-standard;
  }
}
