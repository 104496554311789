@import '../../stylesheets/themes/ecosystem-default/colors';

.FileDownloadLink {
  color: $bright-orange;
  text-decoration: underline;
  margin: 2px 0;
  margin-bottom: 5px;  
  text-align: left;

  &:focus {
    outline: none;
  }
}
