@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/themes/ecosystem-default/colors';

.Pagination {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-semi;
  margin-top: $spacing-standard;
  &__Count {
    color: $mid-grey;
    padding-bottom: $spacing-small;
    text-align: center;
  }

  &__Buttons {
    text-align: center;
    li {
      display: inline-block;
    }
  }

  &__Button {
    background: none;
    border: 2px solid transparent;
    border-radius: 50%;
    color: $bright-orange;
    font-weight: $font-weight-heavy;
    height: 44px;
    margin: 0 $spacing-nano;
    text-align: center;
    transition: border-color .3s ease;
    width: 44px;
    &--active {
      background-color: $bright-orange;
      color: $white;
    }
    &--hidden {
      visibility: hidden;
    }
    &--spaced {
      &--left {
        margin-left: $spacing-micro;
      }
      &--right {
        margin-right: $spacing-micro;
      }
    }
    &--outlined:hover {
      border-color: $dark-orange;
      color: $dark-orange;
    }
  }

  @media only screen and (max-width: 834px) {
    margin-left: 10% !important;
    margin-right: 30% !important;
  }
}

.flexbox_container {
  display: flex;
}