// Spacing old
// $spacing-micro: .25rem;
// $spacing-tiny: .5rem;
// $spacing-small: 1rem;
// $spacing-standard: 1.5rem;
// $spacing-semi: 2rem;
// $spacing-large: 3rem;
// $spacing-xlarge: 4rem;


//spacing new 
$spacing-nano: 2px;
$spacing-micro: 4px;
$spacing-tiny: 8px;
$spacing-smallest: 12px;
$spacing-small: 16px;
$spacing-standard: 24px;
$spacing-semi: 32px;
$spacing-large: 48px;
$spacing-xlarge: 64px;
