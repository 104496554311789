@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';

.accountName {
  font-size: $intro-text;
  padding-top: 20px;
  margin-bottom: 30px;
}

form {
  margin-top: $spacing-small;
}

// media query for tablets
@media only screen and (max-width: 834px) {
  form {
    margin-top: $spacing-small;
    padding-right: 12% !important;
  }
}

@media only screen and (max-width: 820px) {
  form {
    margin-top: $spacing-small;
    padding-right: 13% !important;
  }
}

@media only screen and (max-width: 800px) {
  form {
    margin-top: $spacing-small;
    padding-right: 15% !important;
  }
}

.buttonContainer {
  display: flex;
  button {
    align-self: flex-end;
    margin-right: $spacing-small;
  }
}

.confirmEmail {
  font-weight: bold;
}

.changePassword {
  margin-bottom: $spacing-standard;
  padding-right: 0;
  h4 {
    margin-top: $spacing-standard;
  }
}

.error {
  margin-top: $spacing-small;
  color: $pink;
}

.passwordCriteria {
  margin-top: $spacing-standard;
  padding-left: 50px;
  p {
    margin-bottom: 0px;
    padding-bottom: 10px;
  }
  ul {
    padding-left: 20px;

    /* Add a green text color when the requirements are right */
    .valid {
      color: green;
    }

    /* Add a red text color when the requirements are wrong */
    .invalid {
      color: red;
    }
  }
}

.nav {
  border-bottom: none !important;
  margin-bottom: 20px !important;
  &__navItem {
    margin-right: $spacing-semi;
    padding: $spacing-tiny 0;
    
    &--active {
      border-bottom: 2px solid $dark-orange;
    }

    &__navLink,
    &__navLink:hover {
      color: $dark-grey;
    }

    &__navLink {
      line-height: 1.5;
      text-decoration: none;
      border-width: 0px !important;
      padding: .5rem 0 !important;
      cursor: pointer;

      &--active,
      &--active:hover {
        color: $dark-orange;
      }

      &:hover {
        color: $dark-orange;
        border-width: 0px !important;
      }
    }

    &__navLink:focus {
      outline: none;
    }
  }
}
