/* stylelint-disable color-no-hex */

/* new pallete from january 2020 */
$white:#fff;
$dark-blue:#202d46;
$bright-orange:#e55a00;
$dark-orange:#cf4800;
$darkest-grey:#050505;
$dark-grey:#495057;
$mid-grey:#6b757d;
$light-grey:#ddd;
$grey: #ccc;
$light-blue:#8dc3cb;
$bg-grey:#f5f5f5;
$green:#35934b;
$red:#cc0018;
$teal:#00798b;
$purple:#681868;
$black:#000;
$light-blue: #8dc3cb;
$pink:#e75370;
$yellow:#ebcc62;

// End Colours from design system

//need to address these two
$modal-background: rgba(0, 10, 20, .5);
$bright-orange-200: #ffd07c;


/* stylelint-enable */

$colors: 
  'bg-grey' $bg-grey, 
  'black' $black,
  'bright-orange' $bright-orange,
  'dark-blue' $dark-blue,
  'dark-grey' $dark-grey, 
  'dark-orange' $dark-orange,
  'darkest-grey' $darkest-grey ,
  'green' $green,
  'grey' $grey,
  'light-blue' $light-blue,
  'light-grey' $light-grey,
  'mid-grey' $mid-grey,
  'pink' $pink,
  'purple' $purple,
  'red' $red,
  'teal' $teal,
  'white' $white,
  'yellow' $yellow;
  
/* stylelint-disable */
:export {
  bg-grey:$bg-grey;
  black:$black;
  bright-orange: $bright-orange;
  dark-blue: $dark-blue;
  dark-grey: $dark-grey;
  dark-orange: $dark-orange;
  darkest-grey: $darkest-grey;
  green:$green;
  grey: $grey;
  light-blue:$light-blue;
  light-blue: $light-blue;
  light-grey:$light-grey;
  mid-grey: $mid-grey;
  pink: $pink;
  purple:$purple;
  red:$red;
  teal:$teal;
  white: $white;
  yellow: $yellow;
}
/* stylelint-enable */
