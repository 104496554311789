@import '../../../stylesheets/base/spacing';

.ArrowWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: $spacing-tiny;

  &__Arrow {
    svg {
      height: auto;
      width: 12px;
    }

    &--desc {
      transform: rotate(180deg);
    }
  }
}
