  .main_img {
    padding: 10px;
    padding-top: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ovnHeader {
    display: flex;
    padding-left: 10px;
    text-align: right;
  }

  .moreNews {
    display: flex;
    position: relative;
    padding-right: 25px;
    margin-left: auto;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    &:hover {
      .newsArrow {
        transform: translateX(14px);
        transition: all 0.3s;
        opacity: 5;
      }
    }

  }

  .newsArrow {
    font-size: medium;
    padding-left: 5px;
    cursor: pointer;
    color:  #e55a00;
  }

  .fillGrid {
    height: 260px;
  }

  .title {
    margin-top: 10px;
  }

  .pill {
    position: absolute; 
    bottom: 0;
    padding-top: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
    
    a {
      margin-left: 5px !important;
    }
  }

  .badge {
    margin-right: 5px;
    cursor: pointer;
    height: fit-content;
  }

  .topright {
    position: absolute;
    top: 8px;
    right: 16px;
  }

  .ovnCarousel {
    margin: 10px;
    div {
      div {
        div {
          div{
            margin-right: 5px;
          }
        }

      }
    }
  }

  .carousel {
    margin: 10px;
    div {
      div {
        div {
          div{
            margin-right: 5px;

            :hover {
              filter: brightness(50%);
            }
          }
        }

      }
    }
    button,
    .slick-prev,
    .slick-next {
      &:before {
        color: #e55a00;
      }
    }
  }

  .carousel_item {
    background-color: #e55a00;
    opacity: 0.8;
    height: 100%;
    min-height: 150px;
    position: relative;
    width: 100%;
    cursor: pointer;
    

    h3 {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: large;
      font-weight: bold;
      text-align: center;
    }
  }

  .carousel_item_no_sub {
    background-color: #434343;
    opacity: 0.8;
    height: 100%;
    min-height: 150px;
    position: relative;
    width: 100%;
    cursor: pointer;
    
    h3 {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: large;
      font-weight: bold;
      text-align: center;
    }
  }

  .card_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    padding: 2vw 0px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
  }
  
  .card_item {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-right: 10px;
  }
  
  .cardMedia {
    grid-column: 1;
  }
  
  .cardContent {
    grid-column: 2;
  }

  .modal {
    max-width: 1000px;
  }

  .modal_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    
  }

  .modal_item {
    margin: 10px;
    position: relative;
    flex: 1 0 21%;
    display: block;
    width: 100%;
    height: 100%;

    h1 {
      padding-left: 0px;
      color: #e55a00;
    }
    
    p {
      margin-right: 10px
    }
    
    ul {
      margin-left: 10px;
      margin-right: 10px
    }
  }

  hr.modalContentSeparator {
    border-top: 3px solid #bbb;
  } 

  // media query for tablets
  @media only screen and (max-width: 820px) {
    .main_img {
      padding: 10px;
      padding-top: 20px;
      img {
        width: 85% !important;
        height: 80%;
      }
    }

    .carousel {
      margin: 10px;
      width: 83% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }

    .ovnCarousel {
      margin: 10px;
      width: 84% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
            }
          }
        }
      }
    }

    .card_container {
      max-width: 83% !important;
      
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 800px) {
    .main_img {
      padding: 10px;
      padding-top: 20px;
      img {
        width: 83% !important;
        height: 80%;
      }
    }

    .ovnCarousel {
      margin: 10px;
      width: 83% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
            }
          }
        }
      }
    }

    .carousel {
      margin: 10px;
      width: 82% !important;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }

    .card_container {
      max-width: 83% !important;
      
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }

    .moreNews {
      display: flex;
      position: relative;
      padding-right: 21% !important;
      margin-left: auto;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      &:hover {
        .newsArrow {
          transform: translateX(14px);
          transition: all 0.3s;
          opacity: 5;
        }
      }
    }
    .newsArrow {
      font-size: medium;
      padding-left: 5px;
      cursor: pointer;
      color:  #e55a00;
    }
  }

  @media only screen and (max-width: 834px) {
    .main_img {
      padding: 10px;
      padding-top: 20px;
      img {
        width: 88%;
        height: 88%;
      }
    }

    .ovnHeader {
      font-size:x-small;
      display: flex;
      padding-left: 10px;
      text-align: right;
    }
    .moreNews {
      font-size: medium;
      display: flex;
      position: relative;
      padding-right: 150px;
      margin-top: 10px;
      margin-left: auto;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      &:hover {
        .newsArrow {
          transform: translateX(14px);
          transition: all 0.3s;
          opacity: 5;
        }
      }
    }
    .newsArrow {
      font-size: smaller;
      padding-left: 5px;
      cursor: pointer;
      color:  #e55a00;
    }
    .fillGrid {
      height: 260px;
    }
    .title {
      margin-top: 10px;
    }
    .pill {
      position: absolute;
      bottom: 0;
      padding-top: 10px;
      margin-bottom: 10px;
      white-space: nowrap;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;
      a {
        margin-left: 5px !important;
      }
    }
    .badge {
      margin-right: 5px;
      cursor: pointer;
      height: fit-content;
    }
    .topright {
      position: absolute;
      top: 8px;
      right: 16px;
    }
    .ovnCarousel {
      margin: 10px;
      width: 86%;
      div {
        div {
          div {
            div{
              margin-right: 5px;
            }
          }
        }
      }
    }
    .carousel {
      margin: 10px;
      width: 86%;
      div {
        div {
          div {
            div{
              margin-right: 5px;
              :hover {
                filter: brightness(50%);
              }
            }
          }
        }
      }
      button,
      .slick-prev,
      .slick-next {
        &:before {
          color: #e55a00;
        }
      }
    }
    .carousel_item {
      background-color: #e55a00;
      opacity: 0.8;
      height: 100%;
      min-height: 150px;
      position: relative;
      width: 100%;
      cursor: pointer;
      h3 {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: large;
        font-weight: bold;
        text-align: center;
      }
    }
    .carousel_item_no_sub {
      background-color: #434343;
      opacity: 0.8;
      height: 100%;
      min-height: 150px;
      position: relative;
      width: 100%;
      cursor: pointer;
      h3 {
        color: white;
        position: absolute;
    
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: large;
        font-weight: bold;
        text-align: center;
      }
    }
    .card_container {
      max-width: 86%;
      
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 20px;
      padding: 2vw 0px;
      margin-left: 10px;
      margin-right: 10px;
      justify-content: center;
    }
    .card_item {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      margin-right: 10px;
    }
    .cardMedia {
      grid-column: 1;
    }
    .cardContent {
      grid-column: 2;
    }
    .modal {
      max-width: 1000px;
    }
    .modal_container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .modal_item {
      margin: 10px;
      position: relative;
      flex: 1 0 21%;
      display: block;
      width: 100%;
      height: 100%;
      h1 {
        padding-left: 0px;
        color: #e55a00;
      }
      p {

        margin-right: 10px
      }
      ul {

        margin-left: 10px;
        margin-right: 10px
      }
    }
    hr.modalContentSeparator {
      border-top: 3px solid #bbb;
    }
  }