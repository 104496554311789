@import 'stylesheets/themes/ecosystem-default/colors';
@import 'stylesheets/base/spacing';

.EcosystemApp {
  display: grid;
  grid-template-areas:
    'header'
    'topbar'
    'toolbar'
    'content'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr auto;
  min-height: 100vh;

  &__MainContent {
    grid-area: content;
  }

  &__gfklogo {
    color: $bright-orange;
  }

  &__Error {
    &__Content {
      padding-left: $spacing-small;
    }
  }
}

@media screen and (max-width: 390px) {
  .EcosystemApp {
    display: grid;
    grid-template-areas:
      'header'
      'topbar'
      'toolbar'
      'content'
      'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    min-height: 100vh;
    max-width: 390px;
  }

  .EcosystemApp__MainContent {
    grid-area: content;
  }

  .EcosystemApp__gfklogo {
    color: $bright-orange;
  }

  .EcosystemApp__Error {
    &__Content {
      padding-left: $spacing-small;
    }
  }
}
