@import '../../../stylesheets/themes/ecosystem-default/colors';
@import '../../../stylesheets/base/sizes';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/base/spacing';

.EcosystemFooter {
  background-color: $dark-blue;
  grid-area: footer;

  &__Wrapper {
    align-self: center;
    display: flex;
    height: $ecosystem-footer-height;
    justify-content: space-between;
    padding: $spacing-small 0px;
  
    &__copywrite {
      align-self: center;
      display: flex;
      flex-direction: column;
      font-size: $caption;

      &__app-name {
        color: $white;
        font-weight: bold;
      }

      &__copyright {
        color: $mid-grey;
      }
    }

    &__links {

      &__link {
        color: $light-grey;
        font-size: $body-baseline;
        margin-left: $spacing-standard;
        text-decoration: none;
        align-items: flex-start;
      }
      
      &__link:hover {
        text-decoration: underline;
      }
    }
  }

  // media query for tablets
  @media (max-width: 834px) {
    &__Wrapper {
      &__links__link {
        margin-bottom: $spacing-small;
        margin-right: 100px !important;
      }
    }

    @media (max-width: 820px) {
      &__Wrapper {
        &__links__link {
          margin-bottom: $spacing-small;
          margin-right: 120px !important;
        }
      }
    }

    @media (max-width: 800px) {
      &__Wrapper {
        &__links__link {
          margin-bottom: $spacing-small;
          margin-right: 150px !important;
        }
      }
    }
  }
}
