@import '../../../stylesheets/themes/ecosystem-default/colors';

.Icon {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  svg {
    display: block;
  }

  @each $color in $colors {
    &__#{nth($color, 1)} path {
      fill: nth($color, 2);
    }
  }
}
