@import '../../../stylesheets/base/spacing';
@import '../../../stylesheets/base/fonts';
@import '../../../stylesheets/themes/ecosystem-default/colors';

.Accordion {
  border-bottom: 1px solid $light-grey;
  padding: $spacing-small 0;

  &--open {
    border-bottom: 2px solid $light-grey;
  }

  &__content {
    padding-top: $spacing-small;
  }

  &__chevron {
    color: $bright-orange;
    margin-right: $spacing-small;
  }

  &__header {
    align-items: center;
    display: flex;
  }

  &__button {
    align-items: center;
    color: $body-baseline;
    display: flex;
    font-size: $body-baseline;

    &:focus {
      outline: 2px $light-blue;
    }
  }

  &__title {
    font-size: $body-baseline;
    font-weight: bold;
    margin-bottom: $spacing-small;
  }

  &__subtitle {
    color: $mid-grey;
    font-weight: normal;
    margin-left: $spacing-tiny;
  }

  &--nested {
    border-bottom: 1px solid $light-grey;
    
    .Accordion {
      &__title {
        font-size: larger;
        font-weight: bold;
      }

      &__chevron {
        margin-right: $spacing-tiny;
      }
    }
  }
}
