@import '~normalize.css';
@import './fonts';
@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

html,
body {
  font-family: $font-primary;
  font-size: $body-baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  appearance: none;
  background: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}
