a {
  color: $bright-orange;
  text-decoration:underline;

  &:hover {
    color: $dark-orange;
  }

  &:active {
    color: $bright-orange;
  }

  &:focus {
    outline: 2px solid $light-blue;
  }

}
