@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

$font-primary: 'Lato', sans-serif;
$font-weight-heavy: 800;

// Design System Jan 2020

$body-baseline: 16px;
$intro-text: 18px;
$micro: 12px;
$caption: 14px;
$giant: 48px;
$h1: 32px;
$h1-tablet: 28px;
$h2: 28px;
$h2-tablet: 24px;
$h3: 24px;
$h3-tablet: 20px;
$h4: 20px;







