@import '../../../stylesheets/base/sizes';
@import '../../../stylesheets/base/spacing';

@media screen and (min-width: 1500px) {
  .Wrapper {
    margin: 0 auto;
    width: 1496px;
  }
}

@media screen and (max-width: 1500px) {
  .Wrapper {
    margin: 0 $spacing-semi;
    width: 1360px;
  }
}

@media screen and (max-width: 1200px) {
  .Wrapper {
    margin: 0 $spacing-standard;
    width: 1000px;
  }
}

@media screen and (max-width: 992px) {
  .Wrapper {
    margin: 0 $spacing-standard;
    width: 892px
  }
}

@media screen and (max-width: 768px) {
  .Wrapper {
    margin: 0 $spacing-standard;
    width: 668px;
  }
}

@media screen and (max-width: 600px) {
  .Wrapper {
    margin: 0 $spacing-standard;
    width: 500px;
  }
}
